const config = {
    brand: {
        name: ['Medisch','Centrum','Slotendrecht'],
        imgSrc: './frontpage.jpg'
    }, 
    account: {
        imgSrc: './accountpage.jpg'
    },
    cards: [
        {
            imgSrc: './news.jpg',
            title: 'Haal die prik',
            subtitle: 'Voorkomen is beter dan genezen! Ga gevaccineerd op reis.'
        }
    ]
}

export { config }